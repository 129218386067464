/**
 *
 * @author Vašek Brychta <vaclav@brychtovi.cz>
 * @version 2015-10-17
 */

(function (window, $, undefined) {

	if (typeof $ !== 'function') {
		return console.error('brko.confirmation.js: jQuery is missing, load it please');
	}

	var confirmation = function () {
		var inner = {
			dialog: null,
			reassignLinks: function () {
				$('a.confirm').on('click', function (event) {
					event.preventDefault();
					$.nette.ajax({
									 url: $(this).attr('href'),
									 off: ['history', 'scrollTo'],
									 confirm: true
								 }, $(this));
				});
			}
		};
		this.init = function (confirmationDialog) {
			inner.dialog = confirmationDialog;

			$.nette.ext('confirmationDialog', {
				success: function (payload, status, jqXHR, settings) {
					inner.reassignLinks();
					if (settings.confirm && (
						payload.snippets["snippet-confirmationDialog-dialog-area"] !== undefined
							|| payload.snippets["snippet-stage-confirmationDialog-dialog-area"] !== undefined
						)
						) {
						$(inner.dialog).modal('show');
						$('button', inner.dialog).on('click', function () {
							$(inner.dialog).modal('hide');
						});
					}
				}
			});

			inner.reassignLinks();
		};
	};

	if (window.brko === undefined) {
		window.brko = new (function () {
		});
	}

	window.brko.confirmation = new confirmation();

})(window, window.jQuery);
