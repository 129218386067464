$(function () {

	// Local storage settings
	var themeSettings = getThemeSettings();

	// Elements

	var $app = $('#app');
	var $styleLink = $('#theme-style');
	var $customizeMenu = $('#customize-menu');

	// Color switcher
	var $customizeMenuColorBtns = $customizeMenu.find('.color-item');

	// Position switchers
	var $customizeMenuRadioBtns = $customizeMenu.find('.radio');


	// /////////////////////////////////////////////////

	// Initial state

	// On setting event, set corresponding options

	// Update customize view based on options

	// Update theme based on options

	/************************************************
	*				Initial State
	*************************************************/

	setThemeSettings();

	/************************************************
	*					Events
	*************************************************/

	// set theme type
	$customizeMenuColorBtns.on('click', function() {
		themeSettings.themeName = $(this).data('theme');

		setThemeSettings();
	});


	$customizeMenuRadioBtns.on('click', function() {

		var optionName = $(this).prop('name');
		var value = $(this).val();

		themeSettings[optionName] = value;

		setThemeSettings();
	});

	function setThemeSettings() {
		setThemeState()
		.delay(config.delayTime)
		.queue(function (next) {

			setThemeColor();
			setThemeControlsState();
			saveThemeSettings();

			$(document).trigger("themechange");	
			
			next();
		});	
	}

	/************************************************
	*			Update theme based on options
	*************************************************/

	function setThemeState() {
		// set theme type
		if (themeSettings.themeName) {
			$styleLink.attr('href', $('body').data("base-path") + '/css/admin.' + themeSettings.themeName + '.min.css');
		}
		else {
			$styleLink.attr('href', $('body').data("base-path") + '/css/admin.blue.min.css');
		}

		// App classes
		$app.removeClass('header-fixed footer-fixed sidebar-fixed');

		// set header
		$app.addClass(themeSettings.headerPosition);

		// set footer
		$app.addClass(themeSettings.footerPosition);

		// set footer
		$app.addClass(themeSettings.sidebarPosition);

		return $app;
	}

	/************************************************
	*			Update theme controls based on options
	*************************************************/

	function setThemeControlsState() {
		// set color switcher
		$customizeMenuColorBtns.each(function() {
			if($(this).data('theme') === themeSettings.themeName) {
				$(this).addClass('active');
			}
			else {
				$(this).removeClass('active');
			}
		});

		// set radio buttons
		$customizeMenuRadioBtns.each(function() {
			var name = $(this).prop('name');
			var value = $(this).val();

			if (themeSettings[name] === value) {
				$(this).prop("checked", true );
			}
			else {
				$(this).prop("checked", false );
			}
		});
	}

	/************************************************
	*			Update theme color
	*************************************************/
	function setThemeColor(){
		config.chart.colorPrimary = tinycolor($ref.find(".chart .color-primary").css("color"));	
		config.chart.colorSecondary = tinycolor($ref.find(".chart .color-secondary").css("color"));	
	}

	/************************************************
	*				Storage Functions
	*************************************************/

	function getThemeSettings() {
		var settings = (localStorage.getItem('themeSettings')) ? JSON.parse(localStorage.getItem('themeSettings')) : {};

		settings.headerPosition = settings.headerPosition || '';
		settings.sidebarPosition = settings.sidebarPosition || '';
		settings.footerPosition = settings.footerPosition || '';

		return settings;
	}

	function saveThemeSettings() {
		localStorage.setItem('themeSettings', JSON.stringify(themeSettings));
	}

});