$(function() {
	$("body").addClass("loaded");
});


/***********************************************
*        NProgress Settings
***********************************************/

// start load bar
//NProgress.start();

// end loading bar 
//NProgress.done();
