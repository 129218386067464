/**
 *
 * @author Vašek Brychta <vaclav@brychtovi.cz>
 * @version 2015-08-19
 */

(function (window, $, Foundation, undefined) {

	if (typeof $ !== 'function') {
		return console.error('brko.selectize.js: jQuery is missing, load it please');
	}
	if ($.fn.selectize === undefined) {
		return console.error('brko.selectize.js: Selectize plugin is missing, load it please.');
	}

	var brkoSelectize = function () {
		var inner = {
			selector: null,
			selectize: null,
			createSelectize: function () {
				var $select = $(inner.selector).selectize({
															  onChange: function (value) {
																  if (value != '') {
																	  $(inner.selector).closest('form').submit();
																  }
															  }
														  });

				if ($select.length > 0) {
					inner.selectize = $select[0].selectize;
					inner.selectize.open();
				}
			}
		};

		this.init = function (selector) {
			inner.selector = selector;

			$.nette.ext('selectize', {
				success: function (payload, status, jqXHR, settings) {
					inner.createSelectize();
				}
			});

			inner.createSelectize();
		};
	};

	if (window.brko === undefined) {
		window.brko = new (function () {
		});
	}

	window.brko.selectize = new brkoSelectize();

})(window, window.jQuery, window.Foundation);
